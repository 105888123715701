@font-face {
  font-family: 'Axis';
  src: url('../../common/font/AXISSTD-REGULAR.otf') format('opentype');
}

body {
  background-color: #F5F5F5;
  font-family: Axis, sans-self;
}

.low-container {
  max-width: 520px;
  margin: 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 80px;

  @media only screen and (max-width: 767px) {
    padding-bottom: 40px;
  }
}

.low-title {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  padding-top: 97px;

  @media only screen and (max-width: 767px) {
    padding-top: 77px;
    font-size: 32px;
  }
}

.low-mainImg {
  margin-top: 102px;
  width: 100%;

  @media only screen and (max-width: 767px) {
    margin-top: 42px;
  }
}

.low-text {
  font-size: 16px;
  margin-top: 91px;
  margin-bottom: 91px;
  line-height: 22.8px;

  @media only screen and (max-width: 767px) {
    margin-top: 38px;
    margin-bottom: 38px;
  }
}
